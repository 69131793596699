.machine-container {
  display: flex;
  flex-direction: column;
}

.gif-container {
  display: flex;
  flex-direction: column;
  background-color: #282828;
  border-radius: 16px;
}

.gif-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 1.5rem;
  justify-items: center;
  margin: 0;
  padding: 20px 0 20px 0;
}

.gif-grid .gif-item {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-self: center;
  align-self: center;
  border-radius: 16px;
  background-color: hsl(0deg 0% 0% / 0.25);
  box-shadow:
  0 1px 1px hsl(0deg 0% 0% / 0.075),
  0 2px 2px hsl(0deg 0% 0% / 0.075),
  0 4px 4px hsl(0deg 0% 0% / 0.075),
  0 8px 8px hsl(0deg 0% 0% / 0.075),
  0 16px 16px hsl(0deg 0% 0% / 0.075)
;
}

.gif-item img {
  width: 100%;
  height: 200px;
  border-radius: 10px;
  object-fit: cover;
}
